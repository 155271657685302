.icon-close {
	width: 1048.85px;
	height: 1048.85px;
}

.icon-facebook {
	width: 538.14px;
	height: 1037.32px;
}

.icon-linkedin {
	width: 1078.66px;
	height: 1031.03px;
}

.icon-menu {
	width: 1272.85px;
	height: 1048.86px;
}

.icon-reload {
	width: 1067.5px;
	height: 1067.18px;
}

.icon-search {
	width: 1009.59px;
	height: 1009.77px;
}

.icon-twitter {
	width: 1238.27px;
	height: 1006.35px;
}

.icon-up {
	width: 1078.85px;
	height: 1078.85px;
}

